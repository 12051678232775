<template>
  <div>
    <v-container class="my-4">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click.stop="
                  () => {
                    dialogContent = 'Add';
                    dialog = true;
                  }
                "
              >
                <v-icon class="mr-2">add_circle</v-icon>
                <span>Eğitim Ekle</span>
              </v-btn>
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
            >
              <template v-slot:[`item.disabled`]="{ item }">
                <div class="pl-4">
                  <v-badge dot :color="item.disabled ? 'error' : 'success'" />
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="openLessons(item)">
                      <v-list-item-icon>
                        <v-icon>view_module</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Konular</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="openItem(item)">
                      <v-list-item-icon>
                        <v-icon>visibility</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Göster</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon>
                        <v-icon>create</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Düzenle</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sil</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="600" scrollable>
      <component
        :is="dialogContent"
        @close="dialog = false"
        :course="selectedItem"
        @refresh="getItems"
      />
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "name"
        },
        { text: "Durum", value: "disabled", width: "90px" },
        { text: "Oluşturulma", value: "creationTime", width: "160px" },
        { text: "Güncellenme", value: "updateTime", width: "160px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      selectedItem: {},
      dialog: false,
      dialogContent: ""
    };
  },
  methods: {
    async getItems() {
      this.loading = true;

      try {
        this.items = [];

        // Get all courses
        const query = await firebase
          .firestore()
          .collection("courses")
          .orderBy("name")
          .get();

        query.forEach(doc => {
          if (doc.exists) {
            const course = doc.data();
            this.items.push({
              id: doc.id,
              name: course.name,
              disabled: course.disabled,
              updateTime:
                course.updateTime != null
                  ? new Date(course.updateTime.seconds * 1000).toLocaleString(
                      "tr-TR"
                    )
                  : course.updateTime,
              creationTime:
                course.creationTime != null
                  ? new Date(course.creationTime.seconds * 1000).toLocaleString(
                      "tr-TR"
                    )
                  : course.creationTime
            });
          }
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: `Eğitim listesi alınamadı. ${err.code} ${err.message}`
        });
      }
    },
    openItem(item) {
      this.$router.push({
        name: "admin-course-dashboard",
        params: {
          id: item.id
        }
      });
    },
    async deleteItem(course) {
      const confirmation = await this.$refs.confirm.open(
        "Eğitim Silme",
        "Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify({
          text: "Eğitim siliniyor. Lütfen bekleyin..."
        });

        try {
          // Eğitim kayıtlarını sil
          const qsRegs = await firebase
            .firestore()
            .collection("courseRegistrations")
            .where("courseId", "==", course.id)
            .get();

          qsRegs.forEach(async creg => {
            await firebase
              .firestore()
              .collection("courseRegistrations")
              .doc(creg.id)
              .delete();
          });

          // Konuları sil
          const qsLessons = await firebase
            .firestore()
            .collection("lessons")
            .where("courseId", "==", course.id)
            .get();

          qsLessons.forEach(async lesson => {
            await firebase
              .firestore()
              .collection("lessons")
              .doc(lesson.id)
              .delete();
          });

          // Eğitimi sil
          await firebase
            .firestore()
            .collection("courses")
            .doc(course.id)
            .delete();

          this.$notify({
            type: "success",
            text: "Eğitim başarıyla silindi."
          });
        } catch (err) {
          this.$notify({
            type: "error",
            text: `Eğitim silinemedi. ${err.code} ${err.message}`
          });
        }
      }
    },
    async editItem(item) {
      try {
        // Get course
        const result = await firebase
          .firestore()
          .collection("courses")
          .doc(item.id)
          .get();

        if (result.exists) {
          this.selectedItem = result.data();
          this.selectedItem.id = result.id;

          // Open dialog
          this.dialogContent = "Edit";
          this.dialog = true;
        } else {
          this.$notify({
            type: "error",
            text: "Eğitim bulunamadı"
          });
        }
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Eğitim bilgileri alınamadı. ${err.code} ${err.message}`
        });
      }
    },
    openLessons(item) {
      this.$router.push({
        name: "admin-course-lessons",
        params: {
          id: item.id
        }
      });
    }
  },
  mounted() {
    this.getItems();
  },
  components: {
    Add,
    Edit,
    Confirm
  }
});
</script>
