<template>
  <div id="addCourse">
    <v-card>
      <v-form ref="form" @submit.prevent="add()">
        <v-card-title>Yeni Eğitim</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            id="name"
            v-model="form.name"
            label="Eğitim Adı"
            name="name"
            type="text"
            required
            :rules="nameRules"
          />
          <div class="d-flex justify-space-around">
            <v-text-field
              id="code"
              v-model="form.code"
              label="Eğitim Kodu"
              name="code"
              type="text"
              required
              :rules="codeRules"
            />
            <v-text-field
              id="urlCode"
              v-model="form.urlCode"
              label="URL Kodu"
              name="urlCode"
              type="text"
              required
              :rules="urlCodeRules"
              class="ml-4"
            />
          </div>
          <v-text-field
            id="ebook"
            v-model="form.ebook"
            label="e-Kitap Adresi"
            name="ebook"
            type="text"
          />
          <v-textarea
            id="shortDesc"
            v-model="form.desc.short"
            label="Kısa Açıklama"
            name="shortDesc"
            required
            :rules="shortDescRules"
            rows="3"
          />
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2"
              >Detaylı Açıklama</label
            >
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="form.desc.long"
            />
          </div>
          <div class="mt-10">
            <h3>Öğrenme Çıktıları</h3>
            <div class="d-flex justify-space-around align-center">
              <v-text-field
                id="learningOutcome"
                v-model="form.learningOutcome"
                label="Yeni çıktı ekle"
                name="learningOutcome"
                type="text"
              />
              <v-btn icon color="primary" @click="addLearningOutcome()">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </div>
            <ol>
              <li v-for="(item, i) in learningOutcomes" :key="i">
                {{ item }}

                <v-btn icon @click="removeLearningOutcome(i)">
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>
              </li>
            </ol>
          </div>
          <div class="mt-10">
            <h3>Modüller</h3>
            <div class="d-flex justify-space-around align-center">
              <v-text-field
                id="module"
                v-model="form.module"
                label="Yeni modül ekle"
                name="module"
                type="text"
              />
              <v-btn icon color="primary" @click="addModule()">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </div>
            <ol>
              <li v-for="(item, i) in modules" :key="i">
                {{ item }}

                <v-btn icon @click="removeModule(i)">
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>
              </li>
            </ol>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Ekle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export default {
  components: {
    ckeditor: CKEditor.component
  },
  data: () => ({
    form: {
      name: "",
      code: "",
      urlCode: "",
      desc: {
        short: "",
        long: ""
      },
      learningOutcome: "",
      module: "",
      ebook: ""
    },
    learningOutcomes: [],
    modules: [],
    submitDisabled: false,
    editor: ClassicEditor,
    editorConfig: {
      language: "tr",
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo"
      ]
    }
  }),
  computed: {
    nameRules() {
      return [v => !!v || "Lütfen eğitim adını yazınız"];
    },
    codeRules() {
      return [v => !!v || "Lütfen eğitim kodunu yazınız"];
    },
    urlCodeRules() {
      return [
        v => !!v || "Lütfen URL kodunu yazınız",
        v => /^[a-z0-9-]*$/.test(v) || "Geçersiz URL kodu"
      ];
    },
    shortDescRules() {
      return [v => !!v || "Lütfen açıklama yazınız"];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.form.desc.long = "";
      this.learningOutcomes = [];
      this.modules = [];
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async add() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify({
          text: "Eğitim ekleniyor. Lütfen bekleyin..."
        });

        try {
          const now = firebase.firestore.FieldValue.serverTimestamp();

          const newCourse = {
            code: this.form.code,
            creationTime: now,
            desc: {
              short: this.form.desc.short,
              long: this.form.desc.long
            },
            disabled: false,
            name: this.form.name,
            updateTime: null,
            urlCode: this.form.urlCode,
            learningOutcomes: this.learningOutcomes,
            modules: this.modules,
            ebook: this.form.ebook
          };

          // Add course to database
          await firebase
            .firestore()
            .collection("courses")
            .add(newCourse);

          setTimeout(() => {
            this.submitDisabled = false;
            this.refresh();
            this.close();
          }, 1000);

          this.$notify({
            type: "success",
            text: "Eğitim başarıyla eklendi."
          });
        } catch (err) {
          this.submitDisabled = false;
          this.$notify({
            type: "error",
            text: `Eğitim eklenemedi. ${err.code} ${err.message}`
          });
        }
      }
    },
    addLearningOutcome() {
      this.learningOutcomes.push(this.form.learningOutcome);
      this.form.learningOutcome = "";
    },
    removeLearningOutcome(i) {
      this.learningOutcomes.splice(i, 1);
    },
    addModule() {
      this.modules.push(this.form.module);
      this.form.module = "";
    },
    removeModule(i) {
      this.modules.splice(i, 1);
    }
  }
};
</script>

<style lang="scss">
#addCourse {
  .ck-content {
    min-height: 100px;
  }
}
</style>
